import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import { getLogsByDocumentId, getDocumentDashboardStats } from '../Services/LogServices';
import Pagination from '../Components/Pagination.js';

const ContainerDashboardDocuments = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;
    width: 100%;
    min-height: 70vh;

    p {
        font-size: 1.2rem;
    }
`;

const StatEntry = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 80%;
    padding: 20px;
    margin-bottom: 20px;
`;

const LogEntry = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 80%;
    padding: 20px;
    margin-bottom: 20px;
`;

function DashboardDocuments() {
    const { token } = useAuth();
    const { id } = useParams();
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [stats, setStats] = useState({ viewCount: 0, addToFolderCount: 0 });

    useEffect(() => {
        async function fetchDocumentStats() {
            try {
                const data = await getDocumentDashboardStats(token, id);
                setStats(data);
            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
        fetchDocumentStats();
    }, [id, token]);

    useEffect(() => {
        async function fetchDocumentLogs() {
            try {
                const { logs: logsData, totalPages } = await getLogsByDocumentId(token, id, currentPage);
                setLogs(logsData);
                setTotalPages(totalPages);
            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
        fetchDocumentLogs();
    }, [id, currentPage, token]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <ContainerDashboardDocuments>

            <h1>Document Dashboard Stats</h1>
            <StatEntry>
                <p><strong>View Count:</strong> {stats.viewCount}</p>
                <p><strong>Add to Folder Count:</strong> {stats.addToFolderCount}</p>
            </StatEntry>

            <h1>Document Activity Logs</h1>
            {logs.length > 0 ? (
                logs.map((log, index) => (
                    <LogEntry key={index}>
                        <p>
                            {log.action === 'add_to_folder' && (
                                <>
                                    <p><strong>Action:</strong> {log.action}</p>
                                    <strong>Details:</strong> The document <strong>{log.documentId?.title || 'N/A'}</strong> was added to a folder by user <strong>{log.userId.name}</strong> on {new Date(log.timestamp).toLocaleString()}.
                                </>
                            )}
                            {log.action === 'view' && (
                                <>
                                    <p><strong>Action:</strong> {log.action}</p>
                                    <strong>Details:</strong> The document <strong>{log.documentId?.title || 'N/A'}</strong> was viewed by user <strong>{log.userId.name}</strong> on {new Date(log.timestamp).toLocaleString()}.
                                </>
                            )}
                        </p>
                    </LogEntry>
                ))
            ) : (
                <p>No logs found for this document.</p>
            )}

            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            
        </ContainerDashboardDocuments>
    );
}


export default DashboardDocuments;
