import styled from 'styled-components';

const PrivacyPolicyContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 40px 15%;
    line-height: 1.6;
    color: #333;
    text-align: center;
    
    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
    
    p {
        font-size: 1.1em;
        margin: 10px 0;
    }
`;

function PrivacyPolicy() {
    return (
        <PrivacyPolicyContainer>
            <h1>Privacy Policy</h1>
            <p>We are committed to protecting the privacy of our users. This site collects information such as name, email, and IP address. We use your data only for specific purposes, such as browsing behavior analysis and sending updates, always with your consent. We do not share your information with third parties without permission.</p>
            <p>For any questions, please contact us at: <a href="mailto:contact@internationally.info">contact@internationally.info</a></p>
        </PrivacyPolicyContainer>
    );
}

export default PrivacyPolicy;
