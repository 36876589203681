import axios from "axios";

const paymentAPI = axios.create({baseURL: 'https://internationally.eastus.cloudapp.azure.com/payment'});

async function payment(items) {
    
    const response = await paymentAPI.post("/create-checkout-session", { items });
    window.location.href = response.data.url;
}

export {
    payment
};