import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../Services/UsersServices.js';
import { useAuth } from '../contexts/AuthContext.js';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: #f8f9fa;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Column = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

const CardBody = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const FormCheck = styled.div`
  margin-bottom: 1rem;
`;

const FormCheckInput = styled.input`
  margin-right: 0.5rem;
`;

const FormCheckLabel = styled.label`
  display: inline-block;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const TextCenter = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth(); 
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      const response = await loginUser({ email, password });

      const userData = { id: response.id, name: response.name, email: response.email, package: response.package };

      
      if (response.token) {
        login(userData, response.token);

      } else {
        login(userData, null); 
      }

      navigate('/');
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid email or password");
      } else {
        alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <Card>
            <CardBody>
              <CardTitle>Login</CardTitle>
              <form onSubmit={handleLogin}>
                <FormGroup>
                  <Label htmlFor="email">Email address</Label>
                  <Input
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="password">Password</Label>
                  <Input
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
                <FormCheck>
                  <FormCheckInput type="checkbox" id="remember" />
                  <FormCheckLabel htmlFor="remember">Remember me</FormCheckLabel>
                </FormCheck>
                <Button type="submit">Login</Button>
              </form>
              <TextCenter>
                <Link to="/password_reset">Forgot password?</Link>
              </TextCenter>
              <TextCenter>
                <Link to="/subscription">Sign up</Link>
              </TextCenter>
            </CardBody>
          </Card>
        </Column>
      </Row>
    </Container>
  );
}

export default SignIn;
