import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { changePassword } from '../Services/UsersServices.js'; 

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: #f8f9fa;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 30%;
  margin-top: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const SubTitle = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
  color: #007bff;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

function ChangePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { email } = useParams();
  const navigate = useNavigate();
  
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
        alert('Passwords do not match');
        return;
    }

    try {
        await changePassword(email, password, token);  
        alert('Password changed successfully');
        navigate('/SignIn');
        
    } catch (error) {
      alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
    }
};

  return (
    <Container>
      <Title>Change password for</Title>
      <SubTitle>{email}</SubTitle>
      <Card>
        
        <form onSubmit={handleChangePassword}>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword">Confirm password</Label>
            <Input
              type="password"
              id="confirmPassword"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormGroup>
          <Button type="submit">Change password</Button>
        </form>
      </Card>
    </Container>
  );
}

export default ChangePassword;
