import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  align-self: center;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  max-width: 900px;
  margin: 20px;
  border-radius: 8px;
`;

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
`;

const FormControl = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const PrimaryButton = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #10222e;
  border: 1px solid #10222e;
  display: block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #0e1c25;
    border-color: #0e1c25;
  }
`;

const LinkButton = styled.button`
  background: none;
  border: ${(props) => (props.$isFocused ? '1px solid #007bff' : 'none')};
  box-shadow: ${(props) => (props.$isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none')};
  color: #10222e;
  text-decoration: none;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-top: 10px;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const SearchBar = () => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [isLinkButtonFocused, setIsLinkButtonFocused] = useState(false);
  const [searchParams, setSearchParams] = useState({
    basic: '',
    title: '',
    date: '',
    parties: '',
    category: '',
    tags: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSearchParams({ ...searchParams, [id]: value });
  };

  const handleBasicSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchParams.basic}`);
  };

  const handleAdvancedSearchSubmit = (e) => {
    e.preventDefault();
    const { title, date, parties, category, tags } = searchParams;
    navigate(`/search?title=${title}&date=${date}&parties=${parties}&category=${category}&tags=${tags}`);
  };

  return (
    <StyledContainer>
      <StyledRow>
        <StyledCard>
          <form id="basic-search-form" onSubmit={handleBasicSearchSubmit}>
            <StyledFormGroup>
              <FormLabel htmlFor="basic">Search:</FormLabel>
              <FormControl
                type="text"
                id="basic"
                placeholder="Enter search query"
                value={searchParams.basic}
                onChange={handleInputChange}
              />
            </StyledFormGroup>
            <PrimaryButton type="submit">Search</PrimaryButton>
          </form>
          <LinkButton
            id="toggle-advanced-search"
            onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
            onFocus={() => setIsLinkButtonFocused(true)}
            onBlur={() => setIsLinkButtonFocused(false)}
            $isFocused={isLinkButtonFocused}
          >
            Advanced Search
          </LinkButton>
          {advancedSearchVisible && (
            <div id="advanced-search" className="mt-3">
              <form id="search-form" onSubmit={handleAdvancedSearchSubmit}>
                <StyledFormGroup>
                  <FormLabel htmlFor="title">Title:</FormLabel>
                  <FormControl
                    type="text"
                    id="title"
                    placeholder="Enter document title"
                    value={searchParams.title}
                    onChange={handleInputChange}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="date">Date:</FormLabel>
                  <FormControl
                    type="date"
                    id="date"
                    value={searchParams.date}
                    onChange={handleInputChange}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="parties">Parties Involved:</FormLabel>
                  <FormControl
                    type="text"
                    id="parties"
                    placeholder="Enter parties involved"
                    value={searchParams.parties}
                    onChange={handleInputChange}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="category">Category:</FormLabel>
                  <FormSelect
                    id="category"
                    value={searchParams.category}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a category</option>
                    <option value="Constitutions">Constitutions</option>
                    <option value="Criminal">Criminal</option>
                    <option value="Humanitarian">Humanitarian</option>
                    <option value="Diplomacy">Diplomacy</option>
                    <option value="Labour">Labour</option>
                    <option value="Human Rights">Human Rights</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Maritime">Maritime</option>
                    <option value="Contracts">Contracts</option>
                    <option value="Immigration">Immigration</option>
                  </FormSelect>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="tags">Tags (comma separated):</FormLabel>
                  <FormControl
                    type="text"
                    id="tags"
                    placeholder="Enter tags"
                    value={searchParams.tags}
                    onChange={handleInputChange}
                  />
                </StyledFormGroup>
                <PrimaryButton type="submit">Search</PrimaryButton>
              </form>
            </div>
          )}
        </StyledCard>
      </StyledRow>
    </StyledContainer>
  );
};

export default SearchBar;
