import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { getDocumentsByID, patchDocuments } from '../Services/DocumentsServices.js';
import { useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js'; 
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCol = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 0 15px;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  border-radius: 8px;
  gap: 15px;
`;

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
`;

const FormControl = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const PrimaryButton = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: #10222e;
  border-color: #10222e;
  display: block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #0e1c25;
    border-color: #0e1c25;
  }
`;

const StyledKeyWords = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const KeywordTag = styled.span`
  background-color: #e0e0e0;
  color: #000000;
  border-radius: 12px;
  padding: 5px 10px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  cursor: pointer; 
`;

const Notification = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  color: ${props => (props.success ? 'green' : 'red')};
  background-color: ${props => (props.success ? '#d4edda' : '#f8d7da')};
  border-color: ${props => (props.success ? '#c3e6cb' : '#f5c6cb')};
`;

function RoutesUpdateDocument() {

  const { id } = useParams();

  const [documentTitle, setDocumentTitle] = useState('');
  const [documentDate, setDocumentDate] = useState('');
  const [documentParties, setDocumentParties] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [video, setVideo] = useState('');
  const [tagInput, setTagInput] = useState('');
  const [documentContent, setDocumentContent] = useState('');
  const [notification, setNotification] = useState({ message: '', success: false });

  const { token } = useAuth(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
        alert("Login is needed to update Documents!");
        navigate('/SignIn'); 
    }
    async function fetchDocument() {
        try {
            const fetchedDocument = await getDocumentsByID(id, token);
            setDocumentTitle(fetchedDocument.title);
            setDocumentDate(fetchedDocument.date.substring(0, 10)); 
            setDocumentParties(fetchedDocument.partiesInvolved);
            setCategory(fetchedDocument.category);
            setTags(fetchedDocument.tags);
            setDocumentContent(fetchedDocument.content);
            setVideo(fetchedDocument.video);

        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    }
    fetchDocument();
}, [id, token, navigate]);

  const handleTagKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (tagInput.trim() !== '') {
        setTags([...tags, tagInput.trim()]);
        setTagInput('');
      }
    }
  };

  const handleTagClick = (tagToDelete) => {
    setTags(tags.filter(tag => tag !== tagToDelete));
  };

  const handleEditorChange = (content) => {
    setDocumentContent(content);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const documentData = {
      title: documentTitle,
      date: documentDate,
      partiesInvolved: documentParties,
      category: category,
      content: documentContent,
      tags: tags,
      video: video
    };
  
    try {
      await patchDocuments(id, documentData, token);
      setNotification({ message: "Document saved successfully", success: true });
      setDocumentTitle('');
      setDocumentDate('');
      setDocumentParties('');
      setCategory('');
      setTags([]);
      setTagInput('');
      setVideo('');
      handleEditorChange('');
      
    } catch (error) {
      setNotification({ message: "Error saving document", success: false });
    }
  };

  return(
    <StyledContainer>
      <h1>Update Document</h1>
      <StyledRow>
        <StyledCol>
          <StyledCard>
            <FormLabel htmlFor="document-title">Title:</FormLabel>
            <FormControl
              type="text"
              id="document-title"
              placeholder="Enter Document Title"
              value={documentTitle}
              onChange={(e) => setDocumentTitle(e.target.value)}
            />
            <div>
              <form onSubmit={handleSubmit}>
                <StyledFormGroup>
                  <FormLabel htmlFor="document-date">Date:</FormLabel>
                  <FormControl
                    type="date"
                    id="document-date"
                    value={documentDate}
                    onChange={(e) => setDocumentDate(e.target.value)}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="document-parties">Parties Involved:</FormLabel>
                  <FormControl
                    type="text"
                    id="document-parties"
                    placeholder="Enter Parties Involved"
                    value={documentParties}
                    onChange={(e) => setDocumentParties(e.target.value)}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="category">Category:</FormLabel>
                  <FormSelect
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select a category</option>
                    <option value="Constitutions">Constitutions</option>
                    <option value="Criminal">Criminal</option>
                    <option value="Humanitarian">Humanitarian</option>
                    <option value="Diplomacy">Diplomacy</option>
                    <option value="Labour">Labour</option>
                    <option value="Human Rights">Human Rights</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Maritime">Maritime</option>
                    <option value="Contracts">Contracts</option>
                    <option value="Immigration">Immigration</option>
                  </FormSelect>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="mytextarea">Content:</FormLabel>
                  <Editor
                    apiKey='h5mq973gvkm1rqffak6qbqr2ph7l9m2q9t05h1nhjp63ft2l'
                    initialValue={documentContent}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="tag-input">Add Tags:</FormLabel>
                  <FormControl
                    type="text"
                    id="tag-input"
                    placeholder="Enter a tag and press Enter"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyPress={handleTagKeyPress}
                  />
                </StyledFormGroup>
                <StyledKeyWords>
                  {tags.map((tag, index) => (
                    <KeywordTag key={index} onClick={() => handleTagClick(tag)}>
                      {tag}
                    </KeywordTag>
                  ))}
                </StyledKeyWords>
                <StyledFormGroup>
                  <FormLabel htmlFor="document-video">Video Link:</FormLabel>
                  <FormControl
                    type="text"
                    id="document-video"
                    placeholder="Enter the video link"
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}
                  />
                </StyledFormGroup>
                <PrimaryButton type="submit" id="submit-button">
                  Submit
                </PrimaryButton>
              </form>
              {notification.message && (
                <Notification success={notification.success}>
                  {notification.message}
                </Notification>
              )}
            </div>
          </StyledCard>
        </StyledCol>
      </StyledRow>
    </StyledContainer>
  );
}

export default RoutesUpdateDocument;