import styled from 'styled-components';

const CookiesContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 40px 15%;
    line-height: 1.6;
    color: #333;
    text-align: center;
    
    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
    
    p, li {
        font-size: 1.1em;
        margin: 10px 0;
    }
    
    ul {
        list-style-type: none;
        padding: 0;
    }
`;

function Cookies() {
    return (
        <CookiesContainer>
            <h1>Cookie Policy</h1>
            <p>Our website uses cookies to enhance your experience and ensure the full functionality of all features. Cookies help us better understand how our users interact with international law content and support ongoing improvements. You can manage your cookie preferences by clicking on "Cookie Settings" and selecting the types that best meet your needs.</p>
            <p>Cookies are small files stored on your device when you access our website. We use different types of cookies:</p>
            <ul>
                <li><strong>Necessary Cookies</strong>: Essential for the site’s operation. Without these cookies, basic functionalities like security and accessibility may be compromised.</li>
                <li><strong>Performance Cookies</strong>: Collect anonymous data on how users interact with the site, allowing us to improve the overall browsing experience.</li>
                <li><strong>Marketing Cookies</strong>: Used to display relevant content and ads according to your preferences and interests.</li>
            </ul>
            <p>You have the right to manage and withdraw your consent at any time by adjusting the cookie settings on our website.</p>
        </CookiesContainer>
    );
}

export default Cookies;
