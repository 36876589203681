import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createUser } from '../Services/UsersServices.js';
import { ImWarning } from "react-icons/im";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: #f8f9fa;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Column = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

const CardBody = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const TextCenter = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const PackageInfo = styled.p`
  text-align: center;
  color: #007bff;
  margin-bottom: 1rem;
`;

const ValidationList = styled.ul`
  margin-top: 0.5rem;
  color: red;
  list-style: none;
  padding-left: 0;
`;

const ValidationItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const WarningIcon = styled(ImWarning)`
  margin-right: 0.5rem;
`;

function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    package: '' 
  });

  const [passwordErrors, setPasswordErrors] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  // Capture the package information from the URL query string
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const packageName = searchParams.get('package');
    if (packageName) {
      setFormData((prevData) => ({
        ...prevData,
        package: packageName
      }));
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));

    if (id === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push("At least 8 characters");
    if (!/\d/.test(password)) errors.push("At least one number");
    if (!/[!@#$%^&*]/.test(password)) errors.push("At least one special character");
    setPasswordErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    if (passwordErrors.length > 0) {
      alert('Please meet all password requirements');
      return;
    }

    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        package: formData.package 
      };

      await createUser(userData);
      alert('User created successfully');
      navigate('/SignIn');
    } catch (error) {
      alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <Card>
            <CardBody>
              <CardTitle>Sign Up</CardTitle>
              <PackageInfo>
                You are signing up for the <strong>{formData.package}</strong> package.
              </PackageInfo>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="name">Full Name</Label>
                  <Input type="text" id="name" placeholder="Enter your full name" value={formData.name} onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email">Email address</Label>
                  <Input type="email" id="email" placeholder="Enter email" value={formData.email} onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="password">Password</Label>
                  <Input type="password" id="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                  {passwordErrors.length > 0 && (
                    <ValidationList>
                      <li>Password must contain:</li>
                      {passwordErrors.map((error, index) => (
                        <ValidationItem key={index}>
                          <WarningIcon /> {error}
                        </ValidationItem>
                      ))}
                    </ValidationList>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="confirmPassword">Confirm Password</Label>
                  <Input type="password" id="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} />
                </FormGroup>
                <Button type="submit">Sign Up</Button>
              </form>
              <TextCenter>
                <Link to='/SignIn'>Already have an account? Login</Link>
              </TextCenter>
            </CardBody>
          </Card>
        </Column>
      </Row>
    </Container>
  );
}

export default SignUp;
