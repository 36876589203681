import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getAllFolders, updateFolder } from '../Services/UsersServices.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import { MdCheck } from 'react-icons/md';
import { createLog } from '../Services/LogServices.js'; 
import Pagination from '../Components/Pagination.js';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 30px 10%;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 48px;
    margin-bottom: 40px;
    text-align: center;
`;

const FolderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    gap: 20px;
    width: 100%;
`;

const FolderCard = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.02);
    }
`;

const FolderName = styled.h2`
    font-size: 24px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Circle = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ selected }) => (selected ? '#007bff' : 'transparent')};
    color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
`;

const Button = styled.button`
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 0.25rem;
    background-color: ${({ color }) => color || '#007bff'};
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
        background-color: ${({ hoverColor }) => hoverColor || '#0056b3'};
    }
    &:not(:last-child) {
        margin-right: 10px;
    }
`;

function AddToFolders() {
    const { id } = useParams(); 
    const { user } = useAuth();

    const [folders, setFolders] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState(new Set());
    const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
    const [totalPages, setTotalPages] = useState(1); // Estado para o total de páginas

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchFolders() {
            if (!user) {
                alert("Login is needed to access My Documents!");
                navigate('/');
                return;
            }

            try {
                const response = await getAllFolders(user.id, currentPage); // Enviar a página atual para a API
                setFolders(response.folders);
                setTotalPages(response.totalPages); // Definir o total de páginas
            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }

        fetchFolders();
    }, [user, navigate, id, currentPage]); // Adicione currentPage como dependência

    const toggleFolderSelection = (folderId) => {
        setSelectedFolders(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(folderId)) {
                newSelected.delete(folderId);
            } else {
                newSelected.add(folderId);
            }
            return newSelected;
        });
    };

    const handleAddDocument = async () => {
        if (selectedFolders.size === 0) {
            alert('Please select one or more folders.');
            return;
        }

        try {
            for (let folderId of selectedFolders) {
                const folder = folders.find(f => f._id === folderId);
                if (!folder.documents.includes(id)) {
                    const updatedDocuments = [...folder.documents, id];
                    await updateFolder(user.id, folderId, { documents: updatedDocuments });

                    // Criando um log de adição a pasta
                    await createLog({
                        userId: user.id,
                        action: 'add_to_folder',
                        documentId: id,
                        metadata: { folderId }
                    });
                }
            }

            navigate(-1);
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleCancel = () => {
        navigate(-1); 
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Atualiza a página quando o usuário troca de página
    };

    return (
        <Container>
            <Title>My Documents</Title>
            <FolderGrid>
                {folders.map(folder => (
                    <FolderCard key={folder._id} onClick={() => toggleFolderSelection(folder._id)}>
                        <FolderName>{folder.name}</FolderName>
                        <Circle selected={selectedFolders.has(folder._id)}>
                            {selectedFolders.has(folder._id) && <MdCheck />}
                        </Circle>
                    </FolderCard>
                ))}
            </FolderGrid>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <ButtonContainer>
                <Button color="#dc3545" hoverColor="#c82333" onClick={handleCancel}>Cancel</Button>
                <Button color="#007bff" hoverColor="#0056b3" onClick={handleAddDocument}>Add document</Button>
            </ButtonContainer>
        </Container>
    );
}

export default AddToFolders;
