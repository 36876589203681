import styled from 'styled-components';

const CopyrightContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 40px 15%;
    line-height: 1.6;
    color: #333;
    text-align: center;
    
    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
    
    p {
        font-size: 1.1em;
        margin: 10px 0;
    }
`

function Copyright(){
    
    return (
        <CopyrightContainer>
            <h1>Copyright</h1>
            <p>All content on this site, including text, images, and graphic materials, is protected by copyright. </p>
            <p>Unauthorized use, reproduction, modification, or redistribution of any content is strictly prohibited.</p>
            <p>If you wish to use any material for academic or journalistic purposes, please contact us to obtain the necessary permission.</p>
            <p> All rights reserved.</p>
        </CopyrightContainer>
    );
}

export default Copyright;
