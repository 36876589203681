import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
`;

const ModalBody = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &.secondary {
    background-color: #6c757d;
    color: #fff;

    &:hover {
      background-color: #5a6268;
    }
  }

  &.danger {
    background-color: #dc3545;
    color: #fff;

    &:hover {
      background-color: #c82333;
    }
  }
`;

function ConfirmationModal({ isOpen, title, message, onCancel, onConfirm }) {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <h5>{title}</h5>
              <button onClick={onCancel}>X</button>
            </ModalHeader>
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
              <ModalButton className="secondary" onClick={onCancel}>
                Cancel
              </ModalButton>
              <ModalButton className="danger" onClick={handleConfirm}>
                Confirm
              </ModalButton>
            </ModalFooter>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
}

export default ConfirmationModal;
