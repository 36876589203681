import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getAllFolders, createFolder, deleteFolder, updateFolder } from '../Services/UsersServices.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import { HiPlus } from 'react-icons/hi';
import { MdClose, MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import ConfirmationModal from '../Components/ConfirmationModal.js';
import Pagination from '../Components/Pagination.js';

const FolderContainer = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 30px 10%;
    align-items: center;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;  
`;

const Title = styled.h1`
    font-size: 48px;
    margin-bottom: 40px;
    text-align: center;
    flex-grow: 1;  
`;

const PlusIcon = styled(HiPlus)`
    font-size: 36px;
    cursor: pointer;
    position: absolute;
    right: 0;  
    top: 10px;
`;

const FolderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    gap: 20px;
    width: 100%;
`;

const FolderIcons = styled.div`
    display: flex;
    gap: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #555;
`;

const FolderCard = styled(Link)`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s;
    position: relative;

    &:hover {
        transform: scale(1.02);
    }
`;

const FolderName = styled.h2`
    font-size: 24px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PopupBackground = styled.div`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
`;

const CloseIcon = styled(MdClose)`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
`;

const PopupContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    position: relative;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

function Folder() {
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); 
    const [totalPages, setTotalPages] = useState(1); 
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [isUpdatePopupVisible, setIsUpdatePopupVisible] = useState(false);
    const [folderToUpdate, setFolderToUpdate] = useState(null);
    const [updatedFolderName, setUpdatedFolderName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState(null);

    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchFolders() {
            if (!user) {
                alert("Login is needed to access My Documents!");
                navigate('/');
                return;
            }

            try {
                const response = await getAllFolders(user.id, currentPage);
                setFolders(response.folders);
                setTotalPages(response.totalPages); 
                setLoading(false);

            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }

        fetchFolders();
    }, [user, navigate, currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); 
    };

    const handleCreateFolder = async () => {

        if (user.package === "Free") {
            alert("Upgrade your subscription to create additional folders.");
            return;
        }

        if (!newFolderName.trim()) {
            alert('Please provide a folder name');
            return;
        }

        try {
            const folderData = { name: newFolderName, documents: [] };
            const newFolder = await createFolder(user.id, folderData);
            setFolders([...folders, newFolder]);
            setIsPopupVisible(false);
            setNewFolderName('');
            
        } catch (error) {
            if (error.response && error.response.status === 403) {
                alert("You have reached the folder limit for the Plus plan. Upgrade to create more folders.");
            } else {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
    };

    const handleDeleteClick = (folderId, event) => {
        event.preventDefault();
        setFolderToDelete(folderId);
        setShowDeleteModal(true);
      };
    
      const confirmDelete = async () => {
        try {
            await deleteFolder(user.id, folderToDelete);
            setFolders(folders.filter(folder => folder._id !== folderToDelete));
            setShowDeleteModal(false);
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    };
    
    
      const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleUpdateClick = (folder, event) => {
        event.preventDefault();
        setFolderToUpdate(folder);
        setUpdatedFolderName(folder.name);
        setIsUpdatePopupVisible(true);
    };

    const handleUpdateFolder = async () => {
        if (!updatedFolderName.trim()) {
            alert('Please provide a folder name');
            return;
        }

        try {
            const updatedFolder = await updateFolder(user.id, folderToUpdate._id, { name: updatedFolderName });
            setFolders(folders.map(folder => folder._id === updatedFolder._id ? updatedFolder : folder));
            setIsUpdatePopupVisible(false);
            setFolderToUpdate(null);
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <FolderContainer>
            <TitleContainer>
                <Title>My Documents</Title>
                <PlusIcon onClick={() => setIsPopupVisible(true)} />
            </TitleContainer>
            {loading ? (
                <></>
            ) : (
            <FolderGrid>
                {folders.map(folder => (
                    <FolderCard key={folder._id} to={`/folder/${folder._id}`}>
                        {folder.name !== "Favorite" && ( 
                            <FolderIcons>
                                <FiEdit onClick={(event) => handleUpdateClick(folder, event)} />
                                <MdDelete onClick={(event) => handleDeleteClick(folder._id, event)} />
                            </FolderIcons>
                        )}
                        <FolderName>{folder.name}</FolderName>
                    </FolderCard>
                ))}
            </FolderGrid>
            )}

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />

            <PopupBackground isVisible={isPopupVisible}>
                <PopupContent>
                    <CloseIcon onClick={() => setIsPopupVisible(false)} />
                    <FormGroup>
                        <Label htmlFor="folderName">Folder Name</Label>
                        <Input
                            type="text"
                            id="folderName"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                        />
                    </FormGroup>
                    <Button onClick={handleCreateFolder}>Create</Button>
                </PopupContent>
            </PopupBackground>

            <PopupBackground isVisible={isUpdatePopupVisible}>
                <PopupContent>
                    <CloseIcon onClick={() => setIsUpdatePopupVisible(false)} />
                    <FormGroup>
                        <Label htmlFor="updatedFolderName">New Folder Name</Label>
                        <Input
                            type="text"
                            id="updatedFolderName"
                            value={updatedFolderName}
                            onChange={(e) => setUpdatedFolderName(e.target.value)}
                        />
                    </FormGroup>
                    <Button onClick={handleUpdateFolder}>Update</Button>
                </PopupContent>
            </PopupBackground>

            <ConfirmationModal
                isOpen={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this folder?"
                onCancel={handleCloseDeleteModal}
                onConfirm={confirmDelete}
            />
        </FolderContainer>
    );
}

export default Folder;
