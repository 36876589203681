import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StyledTags from './StyledTags';
import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { FaStar } from "react-icons/fa";

const StyledCard = styled.div`
  margin-bottom: 1rem;
  padding: 20px;
  border: none;
  background-color: #f8f9fa;
`;

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #22455c;
  padding: 10px;
  border-radius: 8px 8px 0 0;
`;

const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

const StyledHeaderIcons = styled.div`
  display: flex;
  gap: 20px;
  font-size: 24px;
  color: #ffffff;
  position: relative;
`;

const StyledCardBody = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ReadMoreButton = styled.button`
  color: #fff;
  background-color: #22455c;
  border: 1px solid #6c757d;
  width: 100%;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #478fbf;
  }
`;

function SearchDocuments({ 
  title, 
  date, 
  partiesInvolved, 
  category, 
  tags, 
  id, 
  content, 
  onDelete, 
  onUpdate, 
  onFavorite 
}) {

  return (
    <StyledCard>

      <StyledCardHeader>
          <StyledHeaderContent>
            <h3>{title}</h3>
            <p>{formatDate(date.substring(0, 10))}</p>
          </StyledHeaderContent>
          <StyledHeaderIcons>
            {onUpdate && <FiEdit style={{ cursor: 'pointer' }} onClick={() => onUpdate(id)} />}
            {onDelete && <MdDelete style={{ cursor: 'pointer' }} onClick={() => onDelete(id)} />}
            {onFavorite && <FaStar style={{ cursor: 'pointer' }} onClick={() => onFavorite(id)} />}
          </StyledHeaderIcons>
      </StyledCardHeader>

      <StyledCardBody>
          <p>
            <strong>Parties Involved:</strong> {partiesInvolved}
          </p>
          <p>
            <strong>Category:</strong> {category}
          </p>
          <p>{stripHtml(content)}</p>
          <StyledTags tags={tags} />
          <Link to={`/search/document/${id}`}>
            <ReadMoreButton>Read More</ReadMoreButton>
          </Link>
      </StyledCardBody>
      
    </StyledCard>
    
  );
}

function stripHtml(html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  var text = tmp.textContent || tmp.innerText || '';

  var lines = text.split('\n');
  if (lines.length > 4) {
    lines = lines.slice(0, 4);
  }

  return lines.join('\n');
}

function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

export default SearchDocuments;
