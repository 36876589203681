import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { resetPassword } from '../Services/UsersServices'; 

const PasswordResetContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  width: 100vw;
  min-height: 70vh;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const InstructionText = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const LinkButton = styled(Link)`
  display: inline-block;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    background-color: #0056b3;
  }
`;

function PasswordReset() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await resetPassword(email);
      setEmailSent(true);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  return (
    <PasswordResetContainer>
      <Title>Reset your password</Title>
      <Card>
        {emailSent ? (
          <>
            <InstructionText>
              Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
            </InstructionText>
            <LinkButton to="/SignIn">Return to sign in</LinkButton>
          </>
        ) : (
          <>
            <InstructionText>
              Enter your user account's verified email address and we will send you a password reset link.
            </InstructionText>
            <form onSubmit={handleSubmit}>
              <Input 
                type="email" 
                id="email" 
                placeholder="Enter your email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              <Button type="submit">Send password reset email</Button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </>
        )}
      </Card>
    </PasswordResetContainer>
  );
}

export default PasswordReset;