import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getDashboardPayments, getDashboardStats } from '../Services/LogServices.js'; 
import { getUsers } from '../Services/UsersServices.js';
import { getAllDocuments } from '../Services/DocumentsServices.js'; 
import { useAuth } from '../contexts/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import Pagination from '../Components/Pagination.js';

const DashboardContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 40px;
    width: 100%;
    gap: 40px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 80%;
    padding: 20px;
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #ddd;
`;

const TabButton = styled.button`
    background: none;
    border: none;
    font-size: 1.2rem;
    padding: 10px 20px;
    cursor: pointer;
    color: ${props => (props.isActive ? "#007bff" : "#666")};
    font-weight: ${props => (props.isActive ? "bold" : "normal")};
    border-bottom: ${props => (props.isActive ? "2px solid #007bff" : "none")};

    &:hover {
        color: #007bff;
    }
`;

const CardContent = styled.div`
    padding: 20px;
    width: 100%;
    text-align: center;
`;

const StatBox = styled.div`
    display: flex;  
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    gap: 20px;
    cursor: pointer;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
        text-align: center;
        color: #333;
    }

    .info-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        font-size: 1rem;
        color: #555;
    }

    .info-item {
        margin: 8px 0;
        flex: 1;
    }
`;

const MiniStatBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 10px;
    min-width: 150px;
    text-align: center;
    flex: 1;

    h2 {
        font-size: 1rem;
        color: #333;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.2rem;
        color: #007bff;
    }
`;

const StatsRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
`;

function Dashboard() {
    const [dashboardStats, setDashboardStats] = useState({});
    const { token } = useAuth(); 
    const [activeTab, setActiveTab] = useState("Users"); 
    const [users, setUsers] = useState([]);
    const [payments, setPayments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate(); 

    useEffect(() => {
        async function fetchData() {
            try {
                const statsData = await getDashboardStats(token); 
                setDashboardStats(statsData);

            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
        fetchData();
    }, [token]);

    useEffect(() => {
        if (activeTab === 'Users') {
            async function fetchUsers() {
                try {
                    const usersData = await getUsers(token, currentPage);
                    setUsers(usersData.users);
                    setTotalPages(usersData.totalPages);
                } catch (error) {
                    alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
                }
            }
            fetchUsers();
        } else if (activeTab === 'Documents') {
            async function fetchDocuments() {
                try {
                    const documentsData = await getAllDocuments(token, currentPage);
                    setDocuments(documentsData.documents);
                    setTotalPages(documentsData.totalPages);
                } catch (error) {
                    alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
                }
            }
            fetchDocuments();
        } else if (activeTab === 'Revenue'){
            async function fetchPayments() {
                try {
                    const paymentsData = await getDashboardPayments(token, currentPage);
                    setPayments(paymentsData.payments);
                    setTotalPages(paymentsData.totalPages);
                
                } catch (error) {
                    alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
                }
            }

            fetchPayments();
        }
    }, [activeTab, token, currentPage]);

    const handlePageChange = (page) => setCurrentPage(page);

    const handleClick = (Id) => {
        navigate(`/dashboard/${Id}`);
    };

    const handleActiveTabChange = (tab) => {
        setCurrentPage(1);
        setActiveTab(tab);
    }

    const renderTabContent = () => {
        if (activeTab === "Users") {
            return (
                <>
                    <StatsRow>
                        <MiniStatBox>
                            <h2>Total Users</h2>
                            <p>{dashboardStats.totalUsers}</p>
                        </MiniStatBox>
                        <MiniStatBox>
                            <h2>New Users This Month</h2>
                            <p>{dashboardStats.newUsersThisMonth}</p>
                        </MiniStatBox>
                        <MiniStatBox>
                            <h2>Active Users Last 30 Days</h2>
                            <p>{dashboardStats.activeUsersLast30Days}</p>
                        </MiniStatBox>
                    </StatsRow>

                    {users.map((user, index) => (
                        <StatBox key={index} onClick={() => handleClick(`users/${user._id}`)}>
                            <h2>{user.name}</h2>
                            <div className="info-container">
                                <span className="info-item"><strong>Email:</strong> {user.email}</span>
                                <span className="info-item"><strong>Package:</strong> {user.package}</span>
                                <span className="info-item"><strong>Super User:</strong> {user.isSuperUser ? "Yes" : "No"}</span>
                                <span className="info-item"><strong>Created At:</strong> {new Date(user.createdAt).toLocaleDateString()}</span>
                            </div>
                        </StatBox>
                    ))}
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </>
            );
        } else if (activeTab === "Documents") {
            return (
                <>
                    <StatsRow>
                        <MiniStatBox>
                            <h2>Total Documents</h2>
                            <p>{dashboardStats.totalDocuments}</p>
                        </MiniStatBox>
                        <MiniStatBox>
                            <h2>Total View Count</h2>
                            <p>{dashboardStats.totalViewCount}</p>
                        </MiniStatBox>
                    </StatsRow>

                    {documents.map((document, index) => (
                        <StatBox key={index} onClick={() => handleClick(`documents/${document._id}`)}>
                            <h2>{document.title}</h2>
                            <div className="info-container">
                                <span><strong>Date:</strong> {new Date(document.date).toLocaleDateString()}</span>
                                <span><strong>Parties Involved:</strong> {document.partiesInvolved || 'N/A'}</span>
                                <span><strong>Category:</strong> {document.category || 'N/A'}</span>
                                <span><strong>Video:</strong> {document.video ? "Yes" : "No"}</span>
                            </div>
                        </StatBox>
                    ))}
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </>
            );
        } else if (activeTab === "Revenue") {
            return (
                <>
                    <StatsRow>
                        <MiniStatBox>
                            <h2>Total Revenue</h2>
                            <p>${dashboardStats.totalRevenue}</p>
                        </MiniStatBox>
                        <MiniStatBox>
                            <h2>Monthly Revenue</h2>
                            <p>${dashboardStats.monthlyRevenue}</p>
                        </MiniStatBox>
                    </StatsRow>
                        
                    {payments.map((payment, index) => (
                        <StatBox key={index}>
                            <h2>
                                {payment.package === 'Plus' ? '$1' : payment.package === 'Pro' ? '$10' : 'N/A'}
                            </h2>
                            <div className="info-container">
                                <span className="info-item"><strong>Email:</strong> {payment.email}</span>
                                <span className="info-item"><strong>Package:</strong> {payment.package}</span>
                                <span className="info-item"><strong>Created At:</strong> {new Date(payment.createdAt).toLocaleDateString()}</span>
                            </div>
                        </StatBox>
                    ))}

                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </>
            );
        }
    };

    return (
        <DashboardContainer>
            <h1>Dashboard</h1>
            <Card>
                <CardHeader>
                    <TabButton isActive={activeTab === "Users"} onClick={() => handleActiveTabChange("Users")}>Users</TabButton>
                    <TabButton isActive={activeTab === "Documents"} onClick={() => handleActiveTabChange("Documents")}>Documents</TabButton>
                    <TabButton isActive={activeTab === "Revenue"} onClick={() => handleActiveTabChange("Revenue")}>Revenue</TabButton>
                </CardHeader>
                <CardContent>
                    {renderTabContent()}
                </CardContent>
            </Card>
        </DashboardContainer>
    );
}

export default Dashboard;
