import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { getLogsByUserId, getUsersDashboardStats } from '../Services/LogServices';
import Pagination from '../Components/Pagination.js';

const ContainerDashboardUsers = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;
    width: 100%;
    min-height: 70vh;

    p {
        font-size: 1.2rem;
    }
`;

const StatEntry = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 80%;
    padding: 20px;
    margin-bottom: 20px;
`;

const LogEntry = styled.div`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 80%;
    padding: 20px;
    margin-bottom: 20px;
`;

function DashboardUsers() {
    const { id } = useParams();
    const { token } = useAuth();
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [stats, setStats] = useState({ viewCount: 0, loginCount: 0 });

    useEffect(() => {
        async function fetchDocumentStats() {
            try {
                const data = await getUsersDashboardStats(token, id);
                setStats(data);
            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
        fetchDocumentStats();
    }, [id, token]);

    useEffect(() => {
        async function fetchUserLogs() {
            try {
                const { logs: logsData, totalPages } = await getLogsByUserId(token, id, currentPage);
                setLogs(logsData);
                setTotalPages(totalPages);
            } catch (error) {
                alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            }
        }
        fetchUserLogs();
    }, [id, currentPage, token]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <ContainerDashboardUsers>
            <h1>User Dashboard Stats</h1>
            <StatEntry>
                <p><strong>View Count:</strong> {stats.viewCount}</p>
                <p><strong>Login Count:</strong> {stats.loginCount}</p>
                <p><strong>Add to Folder Count:</strong> {stats.addToFolderCount}</p>
            </StatEntry>

            <h1>User Activity Logs</h1>
            {logs.length > 0 ? (
                logs.map((log, index) => (
                    <LogEntry key={index}>
                        <p>
                            
                            {log.action === 'add_to_folder' && (
                                <>
                                    <p><strong>Action:</strong> {log.action}</p>
                                    <strong>Details: </strong> The user <strong>{log.userId.name}</strong> added a document titled <strong>{log.documentId?.title || 'N/A'}</strong> to a folder on {new Date(log.timestamp).toLocaleString()}.
                                </>
                            )}
                            {log.action === 'view' && (
                                <>
                                    <p><strong>Action:</strong> {log.action}</p>
                                    <strong>Details: </strong>  The user <strong>{log.userId.name}</strong> viewed a document titled <strong>{log.documentId?.title || 'N/A'}</strong> on {new Date(log.timestamp).toLocaleString()}.
                                </>
                            )}
                            {log.action === 'login' && (
                                <>
                                    <p><strong>Action:</strong> {log.action}</p>
                                    <strong>Details: </strong>  The user <strong>{log.userId.name}</strong> logged in on {new Date(log.timestamp).toLocaleString()}.
                                </>
                            )}
                        </p>
                    </LogEntry>
                ))
            ) : (
                <p>No logs found for this user.</p>
            )}
            
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

        </ContainerDashboardUsers>
    );
}


export default DashboardUsers;
