import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext.js';

import Header from './Components/Header.js';
import Footer from './Components/Footer.js';

import RoutesHome from './Routes/RoutesHome.js';
import RoutesSignIn from './Routes/RoutesSignIn.js';
import RoutesSignUp from './Routes/RoutesSignUp.js';
import RoutesCookies from './Routes/RoutesCookies.js';
import RoutesPrivacyPolicy from './Routes/RoutesPrivacyPolicy.js';
import RoutesTermsConditions from './Routes/RoutesTerms&Conditions.js';
import RoutesCopyright from './Routes/RoutesCopyright.js';
import RoutesSearch from './Routes/RoutesSearch.js';
import RoutesDocuments from './Routes/RoutesDocuments.js';
import RoutesCreateDocuments from './Routes/RoutesCreateDocuments.js';
import RoutesUpdateDocument from './Routes/RoutesUpdateDocument.js';
import RoutesSubscription from './Routes/RoutesSubscription.js';
import RoutesSuccess from './Routes/RoutesSuccess.js';
import RoutesCancel from './Routes/RoutesCancel.js';
import RoutesPasswordReset from './Routes/RoutesPasswordReset.js';
import RoutesChangePassword from './Routes/RoutesChangePassword.js';
import RoutesFolders from './Routes/RoutesFolders.js';
import RoutesFolder from './Routes/RoutesFolder.js';
import RoutesAddToFolders from './Routes/RoutesAddToFolders.js';
import RoutesDashboard from './Routes/RoutesDashboard.js';
import RoutesDashboardUsers from './Routes/RoutesDashboardUsers.js';
import RoutesDashboardDocuments from './Routes/RoutesDashboardDocuments.js';
import RoutesUpgradeSubscription from './Routes/RoutesUpgradeSubscription.js';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li {
    list-style: none;    
  }

  a {
    text-decoration: none; 
    color: inherit; 
  }
`
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle/>
    <BrowserRouter>
      <AuthProvider>
        <Header/>
        <Routes> 
          <Route path='/' element={<RoutesHome/>}/>
          <Route path='/SignIn' element={<RoutesSignIn/>}/>
          <Route path='/SignUp' element={<RoutesSignUp/>}/>
          <Route path='/subscription/success' element={<RoutesSuccess/>}/>
          <Route path='/subscription/cancel' element={<RoutesCancel/>}/>
          <Route path='/subscription' element={<RoutesSubscription/>}/>
          <Route path='/upgrade' element={<RoutesUpgradeSubscription/>}/>
          <Route path='/password_reset/:email' element={<RoutesChangePassword/>}/>
          <Route path='/password_reset' element={<RoutesPasswordReset/>}/>
          <Route path='/cookies' element={<RoutesCookies/>}/>
          <Route path='/privacypolicy' element={<RoutesPrivacyPolicy/>}/>
          <Route path='/copyright' element={<RoutesCopyright/>}/>
          <Route path='/terms&conditions' element={<RoutesTermsConditions/>}/>
          <Route path='/search/document/:id' element={<RoutesDocuments/>}/>
          <Route path='/search/*' element={<RoutesSearch/>}/>
          <Route path='/document/create' element={<RoutesCreateDocuments/>}/>
          <Route path='/document/update/:id' element={<RoutesUpdateDocument/>}/>
          <Route path='/folders' element={<RoutesFolders/>}/>
          <Route path='/folder/:id' element={<RoutesFolder/>}/>
          <Route path='/addtofolders/:id' element={<RoutesAddToFolders/>}/>
          <Route path='/dashboard' element={<RoutesDashboard/>}/>
          <Route path='/dashboard/users/:id' element={<RoutesDashboardUsers/>}/>
          <Route path='/dashboard/documents/:id' element={<RoutesDashboardDocuments/>}/>
        </Routes>
        <Footer/>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
