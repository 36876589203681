import styled from 'styled-components';

const TermsConditionsContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    padding: 40px 15%;
    line-height: 1.6;
    color: #333;
    text-align: center;
    
    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
    
    p {
        font-size: 1.1em;
        margin: 10px 0;
    }
`

function TermsConditions(){
      
    return (
        <TermsConditionsContainer>
            <h1>Terms and Conditions</h1>
            <p>By using this site, you agree to the Terms and Conditions outlined here.</p>
            <p>This site provides educational and informational content on international law, focusing on the dissemination of new laws and judicial decisions.</p>
            <p>All content published is for informational purposes and does not constitute legal advice.</p>
            <p>We are not liable for any direct or indirect damage resulting from the use of the information contained herein. The content is reviewed and updated regularly to ensure accuracy. However, we do not guarantee that all information is fully up-to-date, complete, or error-free.</p>
            <p>We reserve the right to update the Terms and Conditions periodically.</p>
        </TermsConditionsContainer>
    );
}

export default TermsConditions;
