import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CancelContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    width: 100vw;
    min-height: 70vh;
    flex-direction: column;
`

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
`;

const InstructionText = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
`;

const LinkButton = styled(Link)`
  display: inline-block;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    background-color: #0056b3;
  }
  margin-bottom: 15px;
`;

function Cancel(){
    
    return (
        <CancelContainer>
            <Title>Payment Declined</Title>
            <Card>
                <InstructionText>Your payment was declined. Please check if your card is valid, has sufficient funds, and the billing information is correct.</InstructionText>
                <LinkButton to="/subscription">Return to Subscription</LinkButton>
                <LinkButton to="/">Return to Home</LinkButton>
            </Card>
        </CancelContainer>
    );
}

export default Cancel;

