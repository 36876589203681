import styled from 'styled-components';
import { RiArrowLeftDoubleLine, RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
`;

const PageButton = styled.button`
    background-color: ${(props) => (props.isCurrent ? '#007BFF' : '#F0F0F0')};
    color: ${(props) => (props.isCurrent ? 'white' : '#333')};
    border: none;
    margin: 0 5px;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &:hover {
        background-color: ${(props) => (props.isCurrent ? '#007BFF' : '#D0D0D0')};
    }
`;

function Pagination({ currentPage, totalPages, onPageChange }) {
    const pageRange = 2; // Quantidade de botões ao redor da página atual
    const startPage = Math.max(1, currentPage - pageRange);
    const endPage = Math.min(totalPages, currentPage + pageRange);

    const handleFirstPage = () => onPageChange(1);
    const handlePreviousPage = () => onPageChange(currentPage - 1);
    const handleNextPage = () => onPageChange(currentPage + 1);

    return (
        <PaginationContainer>
            {/* Botão para a primeira página */}
            <PageButton onClick={handleFirstPage} disabled={currentPage === 1}>
                <RiArrowLeftDoubleLine />
            </PageButton>

            {/* Botão para a página anterior */}
            <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                <RiArrowLeftSLine />
            </PageButton>

            {/* Botões de paginação dinâmicos */}
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                const page = startPage + index;
                return (
                    <PageButton
                        key={page}
                        isCurrent={page === currentPage}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </PageButton>
                );
            })}

            {/* Botão para a próxima página */}
            <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                <RiArrowRightSLine />
            </PageButton>
        </PaginationContainer>
    );
}

export default Pagination;
