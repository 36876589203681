import styled from 'styled-components';
import SearchDocuments from '../Components/SearchDocuments.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext.js'; 
import { getDocuments } from '../Services/DocumentsServices.js';
import SearchBar from '../Components/SearchBar.js';
import ConfirmationModal from '../Components/ConfirmationModal.js';
import Pagination from '../Components/Pagination.js';
import { deleteDocuments } from '../Services/DocumentsServices.js';

const SearchContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 70vh;
    background-color: #f8f9fa;
`;

const NoDocuments = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f8f9fa;
    margin-bottom: 18vh;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-size: 36px;
    color: #000000; 
    padding: 5px;
`;

function Search() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const { token } = useAuth();

    const query = searchParams.get('query');
    const title = searchParams.get('title');
    const date = searchParams.get('date');
    const parties = searchParams.get('parties');
    const category = searchParams.get('category');
    const tags = searchParams.get('tags');

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [documentSelected, setDocumentSelected] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  
    const [totalPages, setTotalPages] = useState(1);  

    const fetchDocuments = useCallback(async () => { 
        setLoading(true);

        try {

            const fetchedData = await getDocuments(query, title, date, parties, category, tags, currentPage);
            setDocuments(fetchedData.documents);
            setTotalPages(fetchedData.totalPages);  

        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);

        } finally {
            setLoading(false);
        }
    }, [query, title, date, parties, category, tags, currentPage]);  

    useEffect(() => {
        setCurrentPage(1);

    }, [query, title, date, parties, category, tags]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    const handleDelete = (id) => {
        setDocumentSelected(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const confirmDelete = async () => {
        try {
            await deleteDocuments(documentSelected, token);
            setShowDeleteModal(false);
            fetchDocuments();
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    };
    
    const handleUpdate = (id) => {
        navigate(`/document/update/${id}`);
    };

    const handleFavorite = (id) => {
        navigate(`/addtofolders/${id}`);
    };

    return (
        <SearchContainer>
            <SearchBar />

            {loading ? (
                <></>
            ) : documents.length !== 0 ? (
                <>
                    {documents.map(document => (
                        token ? (
                            <SearchDocuments 
                                key={document._id}
                                title={document.title} 
                                date={document.date}
                                partiesInvolved={document.partiesInvolved} 
                                category={document.category} 
                                tags={document.tags}  
                                id={document._id}
                                content={document.content}
                                onDelete={handleDelete}
                                onUpdate={handleUpdate}
                                onFavorite={handleFavorite}
                            />
                        ) : (
                            <SearchDocuments 
                                key={document._id}
                                title={document.title} 
                                date={document.date}
                                partiesInvolved={document.partiesInvolved} 
                                category={document.category} 
                                tags={document.tags}  
                                id={document._id}
                                content={document.content}
                                onFavorite={handleFavorite}
                            />
                        )
                    ))}

                    <Pagination 
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onPageChange={(page) => setCurrentPage(page)} 
                    />

                    <ConfirmationModal
                        isOpen={showDeleteModal}
                        title="Confirm Deletion"
                        message="Are you sure you want to delete this document?"
                        onCancel={handleCloseDeleteModal}
                        onConfirm={confirmDelete}
                    />
                </>
            ) : (
                <NoDocuments><Text>No documents found</Text></NoDocuments>
            )}
        </SearchContainer>
    );
}

export default Search;
