import styled from 'styled-components';
import { FaCheck } from "react-icons/fa";
import { GrCircleQuestion } from "react-icons/gr";
import { useState, useEffect, useCallback, useRef } from 'react';
import { payment } from '../Services/Payment.Services.js';
import { useNavigate } from 'react-router-dom';

const Card = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 700px;
    width: 375px;
    border: 1px solid #000000;
    padding: 20px;
    background-color: rgba(0, 0, 0, .03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 8px;
    gap: 30px;
`;

const Text = styled.section`
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: ${props => props.fontSize || '16px'};           
`;

const BenefitsContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 100%;
    gap: 20px;
    margin-top: 30px;
`;

const BenefitsLines = styled.section`
    display: flex;
    gap: 10px;
    align-items: center; 
    position: relative; 
`;

const Description = styled.section`
    position: absolute; 
    top: 100%; 
    left: 100%;
    transform: translateX(-50%);
    width: 200px; 
    font-size: 12px;
    color: #555;
    background-color: #fff;
    border: 1px solid #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 10;
`;

const StyledButton = styled.button`
    background-color: #007BFF; /* Azul */
    width: 90%;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    
    &:hover {
        background-color: #0056b3; /* Azul mais escuro */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
    
    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Azul de foco */
    }
    
`;

function SubscriptionPlan({ name, value, benefits, descriptions }) {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const toggleDescription = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setExpandedIndex(null);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    
    const handleSubscribe = async () => {
        try {
            if (name === "Free") navigate(`/SignUp?package=Free`)

            else await payment([{ id: name === "Free" ? 1 : name === "Plus" ? 2 : 3, quantity: 1 }]);

        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
            
        }
    };

    return (
        <Card>
            <Text fontSize="38px">{name}</Text>
            <Text fontSize="46px">${value.toFixed(2)}</Text>
            <StyledButton onClick={handleSubscribe}><Text fontSize="16px">Subscribe</Text></StyledButton>
            <BenefitsContainer>
                <Text fontSize="20px">Subscribe to gain access to:</Text>
                {benefits.map((benefit, index) => (
                    <div key={index}>
                        <BenefitsLines> 
                            <FaCheck/>
                            <Text fontSize="16px">{benefit}</Text>
                            <GrCircleQuestion onClick={() => toggleDescription(index)} style={{ cursor: 'pointer' }}/>
                            {expandedIndex === index && <Description ref={containerRef}>{descriptions[index]}</Description>}
                        </BenefitsLines>
                    </div>
                ))}
            </BenefitsContainer>
        </Card>
    );
}

export default SubscriptionPlan;
