import { createLog } from '../Services/LogServices.js'; 
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getVisualizeDocuments } from '../Services/DocumentsServices.js';
import { useParams, useNavigate } from 'react-router-dom'; 
import DOMPurify from 'dompurify'; 
import StyledTags from '../Components/StyledTags.js';
import { useAuth } from '../contexts/AuthContext.js';

const StyledContainer = styled.div`
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  min-height: 70vh;
  background-color: #f8f9fa;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledCard = styled.div`
  width: 65%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  p {
    font-size: 20px; 
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: 20px;
`;

const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
`;

const ContentContainer = styled.div`
  padding: 20px;
  border: 0.5px solid rgba(0, 0, 0, .125);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  margin-bottom: 10px;
  line-height: 1.6;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ul, ol {
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .signature-line {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .signature-name {
    font-weight: bold;
  }
`;

const VideoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

function Document() {

  const { id } = useParams();
  const { user } = useAuth(); 
  
  const [document, setDocument] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    
    if(!user) {
      alert("Login is needed to visualize documents!");
      navigate('/SignIn');
      return;
    } 
  
    async function fetchDocument() {
      try {
        const fetchedDocument = await getVisualizeDocuments(user.id, id);
        setDocument(fetchedDocument);

        if (fetchedDocument.limitExceeded) {
          alert(fetchedDocument.message);
          navigate('/');
          return;
        }

          await createLog({
            userId: user.id,
            action: 'view',
            documentId: id,
            metadata: { count: 1 }
          });
          
        }
      catch (error) {
        alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
      }
    }
  
    fetchDocument();
  }, [id, user, navigate]); 
  
  return (
    <StyledContainer>
      {!document ? (
        <></>
      ) : (
        <StyledCard>
          <StyledCardHeader>
            <h2>{document.title}</h2>
            <p>{formatDate(document.date.substring(0, 10))}</p>
          </StyledCardHeader>
          <StyledCardBody>
            <p><strong>Parties Involved: </strong>{document.partiesInvolved}</p>
            <p><strong>Category: </strong>{document.category}</p>
            <ContentContainer>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(document.content) }} />
            </ContentContainer>
            <p><strong>Tags:</strong></p>
            <StyledTags tags={document.tags}/>
            {!document.video ? (
              <VideoContainer>
                <h3>No video Available</h3>
              </VideoContainer>
              
            ) : (
              <VideoContainer> 
                <YoutubeVideo videoLink={document.video}/> 
              </VideoContainer>
            )}
          </StyledCardBody>
        </StyledCard>
      )}
    </StyledContainer>
  );
}

function YoutubeVideo({ videoLink }) {
  const extractVideoId = (link) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be|youtube\.com)\/(?:watch\?v=)?(.+)/;
    const match = link.match(regExp);
    return match && match[1].length === 11 ? match[1] : null;
  };

  const videoId = extractVideoId(videoLink);

  if (!videoId) {
    return <div>Invalid YouTube video link provided.</div>;
  }

  return (
    <iframe
      width="560" 
      height="315" 
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;   
 picture-in-picture; web-share"
      allowfullscreen
    ></iframe>   
  );
}

function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

export default Document;
