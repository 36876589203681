import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Images/Logo.png';
import { useAuth } from '../contexts/AuthContext';
import ConfirmationModal from './ConfirmationModal'; 

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 15vh;
  justify-content: space-between;
  background-color: #10222e;
`;

const Image = styled.img`
  height: 100%;
  width: auto;
`;

const TextContainer = styled.section`
  display: flex;
  padding: 30px;
  gap: 20px;
`;

const Text = styled.section`
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Text2 = styled.section`
color: #ffffff;
font-family: 'Inter', sans-serif;
font-size: 18px;
`;

function Header() {

  const { user, logout, token } = useAuth();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    logout();
    setShowLogoutModal(false);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <Image src={Logo} alt="Erro" />
      </Link>
      <TextContainer>
        {user ? (
          <>
            <Text2>Welcome, {user.name}</Text2>

            <Link to="/folders">
              <Text>My Documents</Text>
            </Link>
            
            {(user.package === "Free" || user.package === "Plus") && (
              <>
                <Link to="/upgrade">
                  <Text>Upgrade Subscription</Text>
                </Link>
              </>
            )}

            {token && (
              <>
                <Link to="/document/create">
                  <Text>Create new Document</Text>
                </Link>
                <Link to="/dashboard">
                  <Text>Dashboard</Text>
                </Link>
              </>
            )}

            <Text onClick={handleLogoutClick}>Logout</Text>
          </>

        ) : (
          <>
            <Link to="/SignIn">
              <Text>Sign in</Text>
            </Link>
            <Link to="/subscription">
              <Text>Sign up</Text>
            </Link>
          </>
        )}
      </TextContainer>
      <ConfirmationModal
        isOpen={showLogoutModal}
        title="Confirm Logout"
        message="Are you sure you want to logout?"
        onCancel={handleCloseLogoutModal}
        onConfirm={confirmLogout}
      />
    </HeaderContainer>
  );
}

export default Header;
