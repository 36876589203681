import axios from "axios";

const logsAPI = axios.create({ baseURL: 'https://internationally.eastus.cloudapp.azure.com/logs' });

function configureAxios(token) {
    if (token) {
        logsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete logsAPI.defaults.headers.common["Authorization"];
    }
}

// Create a new log
async function createLog(logData) {
    const response = await logsAPI.post("/", logData);
    return response.data;
}

// Service for getting logs by user ID
async function getLogsByUserId(token, id, page = 1, limit = 10) {
    configureAxios(token);

    const response = await logsAPI.get(`/user/${id}?page=${page}&limit=${limit}`);
    return response.data;
}

// Service for getting logs by document ID
async function getLogsByDocumentId(token, id, page = 1, limit = 10) {
    configureAxios(token);

    const response = await logsAPI.get(`/document/${id}?page=${page}&limit=${limit}`);
    return response.data;
}


// Get all logs
async function getAllLogs(token) {
    configureAxios(token);

    const response = await logsAPI.get("/");
    return response.data;
}

// Update a log by ID
async function updateLog(token, id, logData) {
    configureAxios(token);

    const response = await logsAPI.patch(`/${id}`, logData);
    return response.data;
}

// Delete a log by ID
async function deleteLog(token, id) {
    configureAxios(token);

    const response = await logsAPI.delete(`/${id}`);
    return response.data;
}

async function getDashboardStats(token){
    configureAxios(token);
    
    const response = await logsAPI.get(`/stats`);
    return response.data;
}

async function getDocumentDashboardStats(token, id) {
    configureAxios(token);
    const response = await logsAPI.get(`/stats/document/${id}`);
    return response.data;
}

async function getDashboardPayments(token, page = 1, limit = 10) {
    configureAxios(token);

    const response = await logsAPI.get(`/stats/payment?page=${page}&limit=${limit}`);
    return response.data; 
}

async function getUsersDashboardStats(token, id) {
    configureAxios(token);
    const response = await logsAPI.get(`/stats/user/${id}`);
    return response.data;
}

export {
    createLog,
    getLogsByUserId,
    getLogsByDocumentId,
    getAllLogs,
    updateLog,
    deleteLog,
    getDashboardStats,
    getDocumentDashboardStats,
    getDashboardPayments,
    getUsersDashboardStats
};
