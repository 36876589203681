import axios from "axios";

const documentsAPI = axios.create({baseURL: 'https://internationally.eastus.cloudapp.azure.com/documents'});

function configureAxios(token) {
    if (token) {
        documentsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete documentsAPI.defaults.headers.common["Authorization"];
    }
}

async function getAllDocuments(token, page = 1, limit = 10) {
    configureAxios(token);
    
    const response = await documentsAPI.get(`/?page=${page}&limit=${limit}`);
    return response.data;
}

async function getDocuments(query, title, date, parties, category, tags, page = 1, limit = 10) {
    const params = { query, title, date, parties, category, tags, page, limit };

    if (query) {
        const response = await documentsAPI.get('/basicSearch', { params });
        return response.data;
    }

    const response = await documentsAPI.get('/advancedSearch', { params });
    return response.data;
}

async function getDocumentsByID(id) {
    try {
        const response = await documentsAPI.get(`/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return { notFound: true };  
        } else {
            throw new Error(`Erro ao buscar documento: ${error.message}`);
        }
    }
}

async function getVisualizeDocuments(userId, documentId) {
    try {
        const response = await documentsAPI.get(`/view/${userId}/${documentId}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            return { limitExceeded: true, message: "You have reached the viewing limit for today." };
        } else if (error.response && error.response.status === 404) {
            return { notFound: true, message: "Document not found." };
        } else {
            throw new Error(`Error fetching document: ${error.message}`);
        }
    }
}

async function postDocuments(documentData, token) {
    configureAxios(token);
    const response = await documentsAPI.post('', documentData);
    return response.data;
}

async function patchDocuments(id, documentData) {
    const response = await documentsAPI.patch(`/${id}`, documentData);
    return response.data;
}

async function deleteDocuments(id, token) {
    configureAxios(token);
    const response = await documentsAPI.delete(`/${id}`);
    return response.data;
}

export {
    getDocuments,
    getAllDocuments,
    getDocumentsByID,
    postDocuments,
    patchDocuments,
    deleteDocuments,
    getVisualizeDocuments
};
