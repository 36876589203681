import axios from "axios";

const usersAPI = axios.create({ baseURL: 'https://internationally.eastus.cloudapp.azure.com/users' });

function configureAxios(token) {
    if (token) {
        usersAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete usersAPI.defaults.headers.common["Authorization"];
    }
}

// Get all users
async function getUsers(token, page = 1, limit = 10) {
    configureAxios(token);

    const response = await usersAPI.get(`/?page=${page}&limit=${limit}`);
    return response.data; 
}

// Get user by ID
async function getUserByID(id, token) {
    configureAxios(token)

    const response = await usersAPI.get(`/${id}`);
    return response.data;   
}

// Create new user
async function createUser(userData) {
    const response = await usersAPI.post('/', userData);
    return response.data;
}

// Update user by ID
async function updateUser(id, userData, token) {
    configureAxios(token)

    const response = await usersAPI.put(`/${id}`, userData);
    return response.data;
}

// Delete user by ID
async function deleteUser(id, token) {
    configureAxios(token)
    
    const response = await usersAPI.delete(`/${id}`);
    return response.data;
}

// Login user
async function loginUser(loginData) {
    const response = await usersAPI.post('/login', loginData);
    return response.data;
}

async function resetPassword(email) {
    const response = await usersAPI.post('/reset_password', { email });
    return response.data;
}

async function changePassword(email, password, token) {
    configureAxios(token)

    const response = await usersAPI.put('/changePassword', { email, password });
    return response.data;
}

async function getAllFolders(userId, page = 1) {
    const response = await usersAPI.get(`/${userId}/folders`, {
        params: { page, limit: 10 } // Enviar parâmetros de paginação
    });
    return response.data;
}

// Get folder by ID
async function getFolderById(userId, folderId) {

    const response = await usersAPI.get(`/${userId}/folders/${folderId}`);
    return response.data;
}

// Create a new folder for a user
async function createFolder(userId, folderData) {

    const response = await usersAPI.post(`/${userId}/folders`, folderData);
    return response.data;
}

// Update a folder by ID
async function updateFolder(userId, folderId, folderData) {

    const response = await usersAPI.put(`/${userId}/folders/${folderId}`, folderData);
    return response.data;
}

// Delete a folder by ID
async function deleteFolder(userId, folderId) {

    const response = await usersAPI.delete(`/${userId}/folders/${folderId}`);
    return response.data;
}

export {
    getUsers,
    getUserByID,
    createUser,
    updateUser,
    deleteUser,
    loginUser,
    resetPassword,
    changePassword,
    getAllFolders,
    getFolderById,
    createFolder,
    updateFolder,
    deleteFolder
};
