import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { getFolderById, updateFolder } from '../Services/UsersServices.js';
import { getDocumentsByID } from '../Services/DocumentsServices.js';
import { useAuth } from '../contexts/AuthContext.js';
import SearchDocuments from '../Components/SearchDocuments.js';
import ConfirmationModal from '../Components/ConfirmationModal.js';

const FolderContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 70vh;
    background-color: #f8f9fa;
    padding: 20px;
`;

const FolderTitle = styled.h1`
    font-size: 48px;
    margin-bottom: 40px;
    text-align: center;
`;

const DocumentsContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const NoDocuments = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f8f9fa;
    margin-bottom: 18vh;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-size: 36px;
    color: #000000; 
    padding: 5px;
`;

function Folder() {
    const { id } = useParams();
    const [folder, setFolder] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [documentSelected, setDocumentSelected] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { user } = useAuth();

    const fetchFolderAndDocuments = useCallback(async () => {
        setLoading(true);
        try {
            const folderData = await getFolderById(user.id, id);
            setFolder(folderData);
    
            if (folderData.documents.length > 0) {
                const documentPromises = folderData.documents.map(async (docId) => {
                    const document = await getDocumentsByID(docId);
                    if (document.notFound) {
                        
                        const updatedDocuments = folderData.documents.filter(id => id !== docId);
                        setFolder(prevFolder => ({
                            ...prevFolder,
                            documents: updatedDocuments
                        }));
                        
                        await updateFolder(folderData._id, {
                            ...folderData,
                            documents: updatedDocuments
                        });
                        return null;
                    }
                    return document;
                });
    
                const documentResults = await Promise.all(documentPromises);
                
                const validDocuments = documentResults.filter(doc => doc !== null);
                setDocuments(validDocuments);
            }
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    }, [id, user]);
    

    useEffect(() => {
        fetchFolderAndDocuments();
    }, [fetchFolderAndDocuments]);

    useEffect(() => {
        if (folder && folder.documents.length === 0) {
            setDocuments([]);
        }
    }, [folder]);
    

    const handleDelete = (id) => {
        setDocumentSelected(id)
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const confirmDelete = async () => {
        if (!folder || !documentSelected) return;
    
        const updatedDocuments = folder.documents.filter(docId => docId !== documentSelected);
    
        const updatedFolderData = {
            name: folder.name,
            documents: updatedDocuments
        };
    
        try {
            await updateFolder(user.id, folder._id, updatedFolderData);
            setDocuments(prevDocuments => prevDocuments.filter(doc => doc._id !== documentSelected)); 
            setShowDeleteModal(false);
        } catch (error) {
            alert(`An error has occurred: ${error.response?.data?.message || error.message}`);
        }
    };
    
    return (
        <FolderContainer>
            {loading ? (
                <></>
            ) : folder ? (
                <>
                    <FolderTitle>{folder.name}</FolderTitle>
                    <DocumentsContainer>
                        {documents.length !== 0 ? (
                            <>
                                {documents.map(document => (
                                    <SearchDocuments 
                                        key={document._id}
                                        title={document.title} 
                                        date={document.date}
                                        partiesInvolved={document.partiesInvolved} 
                                        category={document.category} 
                                        tags={document.tags}  
                                        id={document._id}
                                        content={document.content}
                                        onDelete={handleDelete}
                                    />
                                ))}

                                <ConfirmationModal
                                    isOpen={showDeleteModal}
                                    title="Confirm Deletion"
                                    message="Are you sure you want to delete this document from the folder?"
                                    onCancel={handleCloseDeleteModal}
                                    onConfirm={confirmDelete}
                                />
                            </>
                        ) : (
                            <NoDocuments><Text>No documents found</Text></NoDocuments>
                        )}
                    </DocumentsContainer>
                </>
            ) : (
                <NoDocuments><Text>Folder not found</Text></NoDocuments>
            )}
        </FolderContainer>
    );
}

export default Folder;