import styled from 'styled-components';
import SubscriptionPlan from '../Components/SubscriptionPlan.js';

const SubscriptionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    min-height: 70vh;
    gap: 30px;
    margin-bottom: 50px;
    margin-top: 30px;
`

const PlansContainer = styled.section`
    display: flex;
    gap: 30px;
`

function Subscription(){

    const freeBenefits = ['Limited number of searches per month'];
    const freeDescription = ['With the Free Plan, you can perform a set number of searches each month. This allows you to access and explore our document database, but there are limits on the number of queries you can make per month.']
    const plusBenefits = ['Unlimited number of searches', 'Document management capabilities', 'Access to video content'];
    const plusDescription = ['The Plus Plan provides you with unrestricted access to our search functionality. You can perform as many searches as you need each month without any limitations.', 'Manage your documents efficiently with the Plus Plan. This feature allows you to organize, edit, and track your documents, giving you full control over your document management tasks.', 'Enjoy exclusive access to a variety of video content with the Plus Plan. This includes instructional videos, webinars, and other valuable resources that enhance your experience and knowledge.']
    const proBenefits = ['Unlimited number of searches', 'Unlimited document management', 'Access to video content'];
    const proDescription = ['The Pro Plan offers unlimited search capabilities, allowing you to perform an infinite number of searches per month. This provides the highest level of access to our comprehensive document database.', 'With the Pro Plan, you gain unrestricted access to document management features. Manage, organize, and edit an unlimited number of documents, ensuring that all your document-related needs are met efficiently.', 'Enjoy exclusive access to a variety of video content with the Plus Plan. This includes instructional videos, webinars, and other valuable resources that enhance your experience and knowledge.7']
    return (
        <SubscriptionContainer>
            <h1>Subscription</h1>
            <PlansContainer>
                <SubscriptionPlan name={"Free"} value={0.00} benefits={freeBenefits} descriptions={freeDescription}/>
                <SubscriptionPlan name={"Plus"} value={1.00} benefits={plusBenefits} descriptions={plusDescription}/>
                <SubscriptionPlan name={"Pro"} value={10.00} benefits={proBenefits} descriptions={proDescription}/>
            </PlansContainer>
        </SubscriptionContainer>
    );
}

export default Subscription;

